
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { mask } from "vue-the-mask";
import { useI18n } from "vue-i18n";
import { CountryListModel } from "@/domain/country/country-list/model/CountryListModel";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";
import { InviteStaffModel } from "@/domain/staff/invite-staff/model/InviteStaffModel";
import i18n from "@/presentation/language/i18n";

export default defineComponent({
  name: "add-staff",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const store = useStore();
    const swalNotification = new SwalNotification();
    const submitButton = ref<HTMLElement | null>(null);
    const inviteStaffModel = ref<InviteStaffModel>({
      countryCode: 397,
    });

    const countryList = ref<CountryListModel[]>([]);
    const countryController = store.state.ControllersModule.countryController;
    const staffController = store.state.ControllersModule.staffController;
    
    const currentPage = ref(1);
    const totalPages = ref(1);
    const isLoading = ref(false);

    //Create form validation object
    const inviteStaffForm = Yup.object().shape({
      phone: Yup.string()
        .required(t("validators_error.required"))
        .label("Phone")
        .min(15, t("validators_error.phoneNumberFull")),
      department: Yup.string()
        .required(t("validators_error.required"))
        .label("Department"),
      jobPosition: Yup.string()
        .required(t("validators_error.required"))
        .label("JobPosition"),
    });

    const getCountryList = async () => {
      if (isLoading.value || currentPage.value > totalPages.value) return;

      isLoading.value = true;

      const countryListModel: CountryListModel = {
        country: {},
        page: currentPage.value,
        pageSize: 50,
      };

      countryController
        .countryList(countryListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((country) => {
              totalPages.value = country.pagination.pageCount;
              countryList.value.push(country);
            });
            currentPage.value++;
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        })
        .finally(() => {
          isLoading.value = false;
        });
        
    };

    const onSubmit = (values) => {
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      inviteStaffModel.value.phoneNumber = inviteStaffModel.value.phoneNumber
        ?.toString()
        .replace(/\D/g, "");

        inviteStaffModel.value.locale = i18n.global.locale.value;

      staffController
        .inviteStaff(inviteStaffModel.value)
        .then((response) => {
          if (response.isSuccess) {
            submitButton.value?.removeAttribute("data-kt-indicator");

            Swal.fire({
              text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_INVITE_STAFF),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("common.button.ok"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
              heightAuto: false,
            }).then(() => {
              router.push({ path: `/staff-list` });
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(async () => {
      setCurrentPageTitle(t("ogza.staffList.inviteStaff.title"));
      setInterval(() => {
        getCountryList();
      }, 500);
    });

    return {
      inviteStaffModel,
      inviteStaffForm,
      countryList,
      onSubmit,
    };
  },
  directives: { mask },
});
