<template>
  <!--begin::Row-->
  <div class="row g-5 g-xl-8">
    <div class="col-xl-12">
      <div class="card mb-xl-8">
        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Heading-->
          <div class="text-center">
            <!--begin::Title-->
            <div class="card-label fw-bolder fs-3 mb-3">
              {{ $t("ogza.staffList.inviteStaff.createStaff") }}
            </div>
            <!--end::Title-->
          </div>
          <!--end::Heading-->
          <!--begin::Form-->
          <Form
            id="kt_account_profile_details_form"
            class="form"
            @submit="onSubmit"
            :validation-schema="inviteStaffForm"
          >
            <!--begin::Card body-->
            <div class="card-body p-9 ps-6 pb-1">
              <div class="row mb-0">
                <div class="col-md-6">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label
                      class="form-label fs-6 fw-bolder text-dark required"
                      >{{ $t("ogza.staffList.inviteStaff.phone") }}</label
                    >
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      name="phone"
                      v-model="inviteStaffModel.phoneNumber"
                      autocomplete="off"
                      placeholder="(###) ###-##-##"
                      v-mask="'(###) ###-##-##'"
                      maxlength="15"
                    />
                    <!--end::Input-->
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="phone" />
                      </div>
                    </div>
                  </div>
                  <!--end::Input group-->
                </div>
                <div class="col-md-6">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <label
                      class="form-label fs-6 fw-bolder text-dark required"
                      >{{ $t("login.country") }}</label
                    >
                    <div class="d-flex align-items-center">
                      <select
                        v-model="inviteStaffModel.countryCode"
                        class="form-select form-select-lg form-select-solid"
                        required
                      >
                        <option
                          v-for="country in countryList"
                          :key="country.country.id"
                          :value="country.country.id"
                        >
                          <img
                            :src="country.country.flagUrl"
                            :alt="country.country.name"
                            class="me-2"
                            width="24"
                            height="24"
                          />{{ country.country.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <!--end::Input group-->
                </div>
              </div>
              <div class="row mb-0">
                <div class="col-md-12">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label
                      class="form-label fs-6 fw-bolder text-dark required"
                      >{{ $t("ogza.staffList.inviteStaff.department") }}</label
                    >
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      name="department"
                      autocomplete="off"
                      v-model="inviteStaffModel.department"
                      :placeholder="
                        $t('ogza.staffList.inviteStaff.enterDepartment')
                      "
                    />
                    <!--end::Input-->
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="department" />
                      </div>
                    </div>
                  </div>
                  <!--end::Input group-->
                </div>
              </div>
              <div class="row mb-0">
                <div class="col-md-12">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label
                      class="form-label fs-6 fw-bolder text-dark required"
                      >{{ $t("ogza.staffList.inviteStaff.position") }}</label
                    >
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      class="form-control form-control-lg form-control-solid"
                      type="text"
                      name="jobPosition"
                      autocomplete="off"
                      v-model="inviteStaffModel.position"
                      :placeholder="$t('ogza.staffList.inviteStaff.enterPosition')"
                    />
                    <!--end::Input-->
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="jobPosition" />
                      </div>
                    </div>
                  </div>
                  <!--end::Input group-->
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <!--begin::Submit button-->
                <button
                  type="submit"
                  id="kt_account_profile_details_submit"
                  ref="submitButton"
                  class="btn btn-primary"
                >
                  <span class="indicator-label"
                    >{{ $t("common.button.create") }}
                  </span>
                  <span class="indicator-progress">
                    {{ $t("common.pleaseWait") }}
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
                <!--end::Submit button-->
              </div>
            </div>
            <!--end::Card body-->
          </Form>
          <!--end::Form-->
        </div>
        <!--end::Body-->
      </div>
    </div>
  </div>
  <!--end::Row-->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { mask } from "vue-the-mask";
import { useI18n } from "vue-i18n";
import { CountryListModel } from "@/domain/country/country-list/model/CountryListModel";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";
import { InviteStaffModel } from "@/domain/staff/invite-staff/model/InviteStaffModel";
import i18n from "@/presentation/language/i18n";

export default defineComponent({
  name: "add-staff",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const store = useStore();
    const swalNotification = new SwalNotification();
    const submitButton = ref<HTMLElement | null>(null);
    const inviteStaffModel = ref<InviteStaffModel>({
      countryCode: 397,
    });

    const countryList = ref<CountryListModel[]>([]);
    const countryController = store.state.ControllersModule.countryController;
    const staffController = store.state.ControllersModule.staffController;
    
    const currentPage = ref(1);
    const totalPages = ref(1);
    const isLoading = ref(false);

    //Create form validation object
    const inviteStaffForm = Yup.object().shape({
      phone: Yup.string()
        .required(t("validators_error.required"))
        .label("Phone")
        .min(15, t("validators_error.phoneNumberFull")),
      department: Yup.string()
        .required(t("validators_error.required"))
        .label("Department"),
      jobPosition: Yup.string()
        .required(t("validators_error.required"))
        .label("JobPosition"),
    });

    const getCountryList = async () => {
      if (isLoading.value || currentPage.value > totalPages.value) return;

      isLoading.value = true;

      const countryListModel: CountryListModel = {
        country: {},
        page: currentPage.value,
        pageSize: 50,
      };

      countryController
        .countryList(countryListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((country) => {
              totalPages.value = country.pagination.pageCount;
              countryList.value.push(country);
            });
            currentPage.value++;
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        })
        .finally(() => {
          isLoading.value = false;
        });
        
    };

    const onSubmit = (values) => {
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      inviteStaffModel.value.phoneNumber = inviteStaffModel.value.phoneNumber
        ?.toString()
        .replace(/\D/g, "");

        inviteStaffModel.value.locale = i18n.global.locale.value;

      staffController
        .inviteStaff(inviteStaffModel.value)
        .then((response) => {
          if (response.isSuccess) {
            submitButton.value?.removeAttribute("data-kt-indicator");

            Swal.fire({
              text: t(SWAL_MESSAGES.SUCCESS.SUCCESS_INVITE_STAFF),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("common.button.ok"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
              heightAuto: false,
            }).then(() => {
              router.push({ path: `/staff-list` });
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(async () => {
      setCurrentPageTitle(t("ogza.staffList.inviteStaff.title"));
      setInterval(() => {
        getCountryList();
      }, 500);
    });

    return {
      inviteStaffModel,
      inviteStaffForm,
      countryList,
      onSubmit,
    };
  },
  directives: { mask },
});
</script>
